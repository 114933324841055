import { Image, SEO, ScrollTo, Section, SliceZone } from "@/components";
import { BoostYourBusiness } from "@/components/sections";
import Lego from "@/images/lego.gif";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import website from "config/website";
import { Link, graphql } from "gatsby";
import { get } from "lodash";
import { Date, RichText } from "prismic-reactjs";
import React from "react";

import blob1 from "./blob.svg";
import blob2 from "./blob1.svg";

const renderProjectTime = (date) => (date ? Date(date).getFullYear() : null);

const Tile = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:nth-of-type(1) {
    background-image: url(${blob1});
  }

  &:nth-of-type(2) {
    background-image: url(${blob2});
  }
`;

export const query = graphql`
  query ProjectQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
              lastPublicationDate
            }
            title
            subtitle
            date
            thumbnail
            excerpt
            complication
            situation
            body {
              ... on PRISMIC_ProjectBodyBanner_full {
                type
                label
                primary {
                  image
                  text
                }
              }
              ... on PRISMIC_ProjectBodyBanner_half {
                type
                label
                primary {
                  image
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Project = ({ data, location, ...props }) => {
  const title = get(data, "prismic.allProjects.edges.0.node.title") || [];
  const subtitle = get(data, "prismic.allProjects.edges.0.node.subtitle") || [];
  const situation =
    get(data, "prismic.allProjects.edges.0.node.situation") || [];
  const complication =
    get(data, "prismic.allProjects.edges.0.node.complication") || [];
  const thumbnail =
    get(data, "prismic.allProjects.edges.0.node.thumbnail") || [];
  const date = get(data, "prismic.allProjects.edges.0.node.date");
  const body = get(data, "prismic.allProjects.edges.0.node.body") || [];

  const meta = get(data, "prismic.allProjects.edges.0.node._meta") || {};

  return (
    <>
      <SEO
        title={`${get(title, "0.text")} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={get(subtitle, "0.text")}
        meta={meta}
        article
      />

      <Section>
        <div className="uk-container uk-container-small">
          <div className="uk-height-large">
            <div className="uk-height-1-1 uk-position-relative">
              <div className="uk-position-top-right">
                <Image {...thumbnail} className="thumbnail" />
              </div>
              <div className="uk-position-bottom-left">
                <h1
                  className="uk-h1 uk-margin-remove"
                  style={{ color: theme.colors.green }}
                >
                  <i>{RichText.asText(title)}</i>
                </h1>
                <div
                  className="uk-h1 uk-margin-remove"
                  style={{ color: theme.colors.green }}
                >
                  <i>{renderProjectTime(date)}</i>
                </div>
                <div
                  className="uk-h1 uk-margin-remove"
                  style={{ color: theme.colors.green }}
                >
                  <i>{RichText.asText(subtitle)}</i>
                </div>
              </div>
            </div>
            <ScrollTo href="#details" />
          </div>
        </div>
      </Section>

      <section
        id="details"
        className="uk-section"
        style={{ marginTop: "100px" }}
      >
        <div className="uk-container uk-container-small">
          <div
            className="uk-child-width-expand@s uk-grid uk-grid-large uk-text-center"
            data-uk-grid
          >
            <Tile className="uk-tile">
              <div className="uk-h4">Situation</div>
              <p>{RichText.asText(situation)}</p>
            </Tile>

            <Tile className="uk-tile">
              <div className="uk-h4">Complication</div>
              <p>{RichText.asText(complication)}</p>
            </Tile>
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default" id={website.skipNavId}>
        <div className="uk-container uk-container-small">
          <article className="uk-article">
            <div className="uk-h2 uk-text-center">Solution</div>
            <SliceZone allSlices={body} />
          </article>
        </div>
      </section>

      <section className="uk-padding-large uk-text-center">
        <Link
          to="/portfolios"
          className="uk-h4 uk-text-bold"
          style={{
            backgroundColor: "rgb(23, 225, 124)",
            padding: "20px 25px 20px 15px",
            borderRadius: "40px",
          }}
        >
          <span uk-icon="icon: arrow-left; ratio: 2" />
          Back to all projects
        </Link>
      </section>
      <div style={{ width: "100vw", marginTop: "40px", marginBottom: "-60px" }}>
        <img
          src={Lego}
          style={{
            width: "120px",
            margin: "auto",
            position: "relative",
            zIndex: -1,
            display: "block",
          }}
          alt="lego"
        />
      </div>
      <BoostYourBusiness />
    </>
  );
};

Project.query = query;

export default Project;
