import { Button } from "@/components";
import React from "react";

export const BoostYourBusiness = () => (
  <section className="uk-section uk-section-large uk-text-center uk-position-relative">
    <h2>Start Building Digital.</h2>
    <Button.CTA color="orange" to="/contact-us">
      Let's Talk
    </Button.CTA>
  </section>
);
