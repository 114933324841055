import theme from "@/styles/theme";
import styled from "@emotion/styled";
import React from "react";

const BlobHorizontal = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
  content: "";
  display: block;
  min-height: 200px;
  min-width: 200px;
  animation: horizontal1 10s ease-in-out infinite both alternate;

  @keyframes horizontal1 {
    0% {
      transform: translate(-30vw, 0vh);
    }
    100% {
      transform: translate(30vw, 0vh);
    }
  }

  @use autoprefixer(
  browsers: "> 3%"
  );
`;

const BlobVertical = styled.div`
  z-index: -1;

  content: "";
  display: block;
  width: 50vh;
  height: 50vh;
  min-height: 200px;
  min-width: 200px;
  background: ${theme.colors.purple};
  background-size: 2000% 2000%;
  transform-style: preserve-3d;
  animation: transform1 7s ease-in-out infinite both alternate,
    vertical1 3s ease-in-out infinite both alternate;

  @keyframes transform1 {
    0%,
    100% {
      border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
      border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
      border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
      border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
      border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
      border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
      border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
  }

  @keyframes vertical1 {
    0% {
      transform: scale(0.9, 1) translate(0vw, -20vh);
    }
    100% {
      transform: translate(0vw, 20vh);
    }
  }
`;

export const Blob = () => (
  <BlobHorizontal style={{ zIndex: -2 }}>
    <BlobVertical />
  </BlobHorizontal>
);
